window.obaia.Helpers = {}
window.obaia.Helpers.setWindowHeight = function () {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
}

window.obaia.Helpers.debounce = function (func, wait, immediate) {
    var timeout
    return function executedFunction() {
        var context = this
        var args = arguments
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args)
        }
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

window.addEventListener('load', window.obaia.Helpers.setWindowHeight, {passive: true})
window.addEventListener('resize', window.obaia.Helpers.debounce(window.obaia.Helpers.setWindowHeight, 200), {passive: true})